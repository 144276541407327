
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class App extends Vue {
  mounted() {
    const designW = 1440; //设计稿宽
    const fontRate = 12;

    setTimeout(() => {
      //适配
      document.getElementsByTagName("html")[0].style.fontSize =
          (document.body.offsetWidth / designW) * fontRate + "px";
      document.getElementsByTagName("body")[0].style.fontSize =
          (document.body.offsetWidth / designW) * fontRate + "px";
    }, 100);

    //监测窗口大小变化
    window.addEventListener(
        "onorientationchange" in window ? "orientationchange" : "resize",
        function () {
          document.getElementsByTagName("html")[0].style.fontSize =
              (document.body.offsetWidth / designW) * fontRate + "px";
          document.getElementsByTagName("body")[0].style.fontSize =
              (document.body.offsetWidth / designW) * fontRate + "px";
        },
        false
    );
  }
}
