
import router from "@/router";
import { Options, Vue } from "vue-class-component";
import { reactive, ref } from "vue";
import { usersLogin, usersRegister } from "@/https/apis";
import { ElMessage } from "element-plus";
import { clearToken, clearUser, getUser, saveToken, saveUser } from "@/https";
import { useStore, mapState, mapMutations } from "vuex";
@Options({
  components: {},
  computed: {
    isUserLogin(): boolean {
      console.log(getUser()?.state === "standard");
      return getUser()?.state === "standard"; //判断是否是普通游客
    },
    ...mapState("Login", ["dialogVisible"]),
  },
  methods: mapMutations("Login", ["updateDialogVisible"]),
})
export default class Index extends Vue {
  isUserLogin: boolean;
  updateDialogVisible: Function;
  state = reactive({
    dialogVisible: false, //对话框可见
    showSignIn: true,
  });
  user = reactive({
    name: null,
    email: null,
    password: null,
  });
  loginStore = useStore();
  errors = reactive({
    email: [],
    password: [],
  });
  created() {
    console.log(this.isUserLogin);
  }
  toIndex() {
    router.push({
      name: "Index",
    });
  }

  signIn() {
    this.updateDialogVisible(true);
    this.state.dialogVisible = true;
  }

  closeSignIn() {
    this.user.name = "";
    this.user.email = "";
    this.user.password = "";
    this.state.showSignIn = true;
    this.updateDialogVisible(false);
  }

  register() {
    usersRegister(this.user).then((res) => {
      if (res.code === 422) {
        this.errors = res.errors;
        for (let i in this.errors) {
          let v = this.errors[i];
          for (let ii in v) {
            let vv = v[ii];
            ElMessage.error(vv);
          }
        }
      } else {
        saveToken(res.data.token);
        saveUser(res.data.user);
        if (this.$route.name === "Index") {
          router.go(0);
        } else {
          router.push({
            name: "Index",
          });
        }
      }
    });
  }
  login() {
    usersLogin(this.user).then((res) => {
      if (res.code === 422) {
        this.errors = res.errors;
        for (let i in this.errors) {
          let v = this.errors[i];
          for (let ii in v) {
            let vv = v[ii];
            ElMessage.error(vv);
          }
        }
      } else {
        saveToken(res.data.token);
        saveUser(res.data.user);
        if (this.$route.name === "Index") {
          router.go(0);
        } else {
          router.push({
            name: "Index",
          });
        }
      }
    });
  }

  showSignInFn() {
    this.user.name = "";
    this.user.email = "";
    this.user.password = "";
    this.state.showSignIn = !this.state.showSignIn;
  }

  logOut() {
    clearToken();
    clearUser();
    if (this.$route.name === "Index") {
      router.go(0);
    } else {
      router.push({
        name: "Index",
      });
    }
  }
}
