<template>
  <div id="nav"></div>
  <div class="page">
    <router-view />
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class App extends Vue {
  mounted() {
    const designW = 1440; //设计稿宽
    const fontRate = 12;

    setTimeout(() => {
      //适配
      document.getElementsByTagName("html")[0].style.fontSize =
          (document.body.offsetWidth / designW) * fontRate + "px";
      document.getElementsByTagName("body")[0].style.fontSize =
          (document.body.offsetWidth / designW) * fontRate + "px";
    }, 100);

    //监测窗口大小变化
    window.addEventListener(
        "onorientationchange" in window ? "orientationchange" : "resize",
        function () {
          document.getElementsByTagName("html")[0].style.fontSize =
              (document.body.offsetWidth / designW) * fontRate + "px";
          document.getElementsByTagName("body")[0].style.fontSize =
              (document.body.offsetWidth / designW) * fontRate + "px";
        },
        false
    );
  }
}
</script>
<style>
.mask {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
}

.el-button {
  /* height: calc(40 / 14 * 1rem); */
  box-shadow: 0px 4px 18px 0px rgba(79, 129, 249, 0.34) !important;
  border-radius: 8px !important;
  border: 0;
}
.el-button--primary {
  background-color: #4f81f9 !important;
  border-color: #4f81f9 !important;
}

body {
  background: #fff;
  color: #555;
  font-size: 14px;
  font-family: Verdana, Arial, Helvetica, sans-serif;
}

html,
body,
#app {
  height: 100%;
}
.page {
  height: 100%;
  width: 100%;
}
/* css 初始化 */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

.flex {
  display: flex;
}

.flex-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.flex-center {
  align-items: center;
}

.box1200 {
  width: 1200px;
  margin: 0 auto;
}

.marginTop30{
  margin-top: 30px;
}

.marginBottom30{
  margin-bottom: 30px;
}
</style>
