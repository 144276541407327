import { createStore } from 'vuex';
import Crumbs from "./modules/crumbs";
import MenuActive from "./modules/menu-active";
import Login from "./modules/login";

export default createStore({
  modules: {
    Crumbs,
    MenuActive,
    Login
  }
})