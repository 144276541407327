import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import SignIn from '../views/sign-in.vue';
import ChildrenMain from '../views/children-main/common.vue';
import { getToken } from "@/https";
import store from "../store"
// import Index from '../views/children-main/common.vue';
// import Study from '../views/children-main/study.vue';
// import TestQuestion from '../views/children-main/test-question.vue';

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   name: 'SignIn',
  //   component: SignIn,
  //   meta: {
  //     title: '登录'
  //   }
  // },
  {
    path: '/',
    name: 'childrenMain',
    component: ChildrenMain,
    redirect: 'index',
    meta: {
      keepAlive: false,
    },
    children: [
      {
        path: 'index',
        name: 'Index',
        component: () => import(/* webpackChunkName: "about" */ '../views/children-main/index.vue'),
        meta: {
          title: '',
        }
      },
      {
        path: 'study/:id',
        name: 'Study',
        component: () => import(/* webpackChunkName: "about" */ '../views/children-main/study.vue'),
        meta: {
          title: '',
        }
      },
      {
        path: 'test-question/:id',
        name: 'TestQuestion',
        component: () => import(/* webpackChunkName: "about" */ '../views/children-main/test-question.vue'),
        meta: {
          title: '',
        }
      },
      {
        path: 'personal-center',
        name: 'PersonalCenter',
        component: () => import(/* webpackChunkName: "about" */ '../views/children-main/personal-center.vue'),
        meta: {
          title: '',
        }
      }
    ]
  }
  // {
  //   path: '/common',
  //   name: 'Common',
  //   component: Common,
  //   meta: {
  //     keepAlive: false,
  //   },
  //   children: [
  //     {
  //       path: 'home',
  //       name: 'Home',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/home/index.vue'),
  //       meta: {
  //         title: '首页',
  //         keepAlive: false,
  //         menuId: "1",
  //         bigMenu: '首页'
  //       }
  //     }
  //   ]
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  if (getToken()) {
    return true;
  } else {

    if (to.name === 'Study') {
      store.commit('Login/updateDialogVisible',true)
      return false
    }

    if (to.name === 'Index') {
      return true;
    }
    router.push('/');
    return false;
  }

})

export default router
