const noRepetition = {
  install(app) {
    app.directive('noRepetition', {
      mounted(el, binding) {
        el.addEventListener('click', () => {
          if (!el.disabled) {
            el.disabled = true
            setTimeout(() => {
              el.disabled = false
            }, binding.value || 2000);
          }
        })
      }
    })
  }
}

export { noRepetition }