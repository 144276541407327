interface loginState {
  dialogVisible: boolean
}
// const state = function () {
//   return {
//     dialogVisible: false
//   }
// }

const state: loginState = {
  dialogVisible: false
}

const mutations = {
  updateDialogVisible(state: loginState, payload: boolean) {
    state.dialogVisible = payload
  }
}

export default {
  namespaced: true,
  state,
  mutations
}