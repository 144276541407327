<template>
  <div>
    <div class="top">
      <div
        class="header box1200 flex flex-between flex-center"
        style="height: 100%"
      >
        <div class="header-left-box flex flex-center">
          <!-- <img
           src="@/assets/img/logo.png"
           alt=""
           class="top-logo"
           @click="toIndex()"
         /> -->
          <div class="logo-text top-logo">
            <img
              src="@/assets/img/logo2.png"
              alt=""
              class="top-logo"
              @click="toIndex()"
            />
          </div>
          <div class="flex flex-center">
            <div class="menu-item">
              <!-- <img src="@/assets/img/top_test.png" alt="" class="menu-img" /> -->
              <span class="menu-text">Test</span>
            </div>
            <div class="menu-item">
              <!-- <img src="@/assets/img/top_video.png" alt="" class="menu-img" /> -->
              <span class="menu-text">Videos</span>
            </div>
            <div class="menu-item">
              <!-- <img src="@/assets/img/top_game.png" alt="" class="menu-img" /> -->
              <span class="menu-text">Games</span>
            </div>
          </div>
          <div class="flex flex-center search-box">
            <div class="search-img">
              <img src="@/assets/img/search.png" alt="" />
            </div>
            <input
              type="text"
              placeholder="What are you looking for?"
              class="search-input"
            />
          </div>
        </div>
        <div class="sign-box flex flex-center">
          <div
            class="btn-bg"
            @click="signIn()"
            v-if="!isUserLogin"
            style="margin-right: 17px"
          >
            <span class="sign-in">Sign in</span>

            <span class="personal-center" v-if="(dialogVisible = false)"
              >Personal center</span
            >
            <img src="@/assets/img/signbg.png" alt="" class="login-in" />
          </div>

          <div class="btn-bg btn-bg-lr" @click="logOut()" v-else>
            <span class="sign-in">Log out</span>

            <img src="@/assets/img/login_out.png" alt="" class="login-in" />
          </div>
          <div class="list-btn btn-bg btn-menu">
            <!-- <img
              src="@/assets/img/top_menu.png"
              alt=""
              class="login-in"
              style="margin-left: 0"
            /> -->
            <div class="horizontal-line"></div>
            <div class="horizontal-line"></div>
            <div class="horizontal-line"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="main">
      <router-view />
    </div>

    <el-dialog
      v-model="dialogVisible"
      :title="state.showSignIn ? 'TO SIGN IN' : 'SIGN IN'"
      width="23%"
      center="true"
      :before-close="closeSignIn"
    >
      <div class="input-box">
        <div class="sign-input-box" v-if="!state.showSignIn">
          <img
            src="@/assets/img/sign-in/ic_account.png"
            alt=""
            class="login-in"
            style="margin-left: 5px"
          />
          <input
            v-model="user.name"
            type="text"
            name="email"
            id=""
            class="sign-input"
            placeholder="Username"
          />
        </div>

        <div class="sign-input-box">
          <img
            src="@/assets/img/sign-in/ic_email.png"
            alt=""
            class="login-in"
            style="margin-left: 5px"
          />
          <input
            v-model="user.email"
            type="text"
            name="email"
            id=""
            class="sign-input"
            :placeholder="state.showSignIn?'Email Or Username':'Email'"
          />
        </div>
        <div class="sign-input-box">
          <img
            src="@/assets/img/sign-in/ic_password.png"
            alt=""
            class="login-in"
            style="margin-left: 5px"
          />
          <input
            v-model="user.password"
            type="password"
            name="password"
            id=""
            class="sign-input"
            placeholder="Password"
          />
        </div>
        <div></div>
      </div>
      <template #footer>
        <span class="dialog-footer" v-if="state.showSignIn">
          <el-button @click="showSignInFn" class="btn-sign">Sign up</el-button>
          <!-- Sign up to Sign in -->
          <el-button @click="login()" class="btn-sign">to Sign in</el-button>
        </span>

        <span class="dialog-footer" v-if="!state.showSignIn">
          <el-button @click="showSignInFn" class="btn-sign">back</el-button>
          <el-button @click="register()" class="btn-sign"
            >Sign up and Sign in</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import router from "@/router";
import { Options, Vue } from "vue-class-component";
import { reactive, ref } from "vue";
import { usersLogin, usersRegister } from "@/https/apis";
import { ElMessage } from "element-plus";
import { clearToken, clearUser, getUser, saveToken, saveUser } from "@/https";
import { useStore, mapState, mapMutations } from "vuex";
@Options({
  components: {},
  computed: {
    isUserLogin(): boolean {
      console.log(getUser()?.state === "standard");
      return getUser()?.state === "standard"; //判断是否是普通游客
    },
    ...mapState("Login", ["dialogVisible"]),
  },
  methods: mapMutations("Login", ["updateDialogVisible"]),
})
export default class Index extends Vue {
  isUserLogin: boolean;
  updateDialogVisible: Function;
  state = reactive({
    dialogVisible: false, //对话框可见
    showSignIn: true,
  });
  user = reactive({
    name: null,
    email: null,
    password: null,
  });
  loginStore = useStore();
  errors = reactive({
    email: [],
    password: [],
  });
  created() {
    console.log(this.isUserLogin);
  }
  toIndex() {
    router.push({
      name: "Index",
    });
  }

  signIn() {
    this.updateDialogVisible(true);
    this.state.dialogVisible = true;
  }

  closeSignIn() {
    this.user.name = "";
    this.user.email = "";
    this.user.password = "";
    this.state.showSignIn = true;
    this.updateDialogVisible(false);
  }

  register() {
    usersRegister(this.user).then((res) => {
      if (res.code === 422) {
        this.errors = res.errors;
        for (let i in this.errors) {
          let v = this.errors[i];
          for (let ii in v) {
            let vv = v[ii];
            ElMessage.error(vv);
          }
        }
      } else {
        saveToken(res.data.token);
        saveUser(res.data.user);
        if (this.$route.name === "Index") {
          router.go(0);
        } else {
          router.push({
            name: "Index",
          });
        }
      }
    });
  }
  login() {
    usersLogin(this.user).then((res) => {
      if (res.code === 422) {
        this.errors = res.errors;
        for (let i in this.errors) {
          let v = this.errors[i];
          for (let ii in v) {
            let vv = v[ii];
            ElMessage.error(vv);
          }
        }
      } else {
        saveToken(res.data.token);
        saveUser(res.data.user);
        if (this.$route.name === "Index") {
          router.go(0);
        } else {
          router.push({
            name: "Index",
          });
        }
      }
    });
  }

  showSignInFn() {
    this.user.name = "";
    this.user.email = "";
    this.user.password = "";
    this.state.showSignIn = !this.state.showSignIn;
  }

  logOut() {
    clearToken();
    clearUser();
    if (this.$route.name === "Index") {
      router.go(0);
    } else {
      router.push({
        name: "Index",
      });
    }
  }
}
</script>

<style scoped>
input::-webkit-input-placeholder{
  color: #CCC;
}
.top {
  /* background: #eb3532; */
  width: calc(100vw - 17px);
  height: 120px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}

.header {
  background: url("~@/assets/img/headerbg.png") no-repeat center -4px;
  background-size: 100%;
}

.header-left-box {
  margin-left: 86px;
}

.logo-text {
  font-size: 33px;
  color: #90d6dd;
  letter-spacing: 3px;
  line-height: 50px;
}

.top-logo {
  /* width: 50px; */
  height: 50px;
  margin-right: 35px;
  cursor: pointer;
}

.menu-item {
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.menu-img {
  width: 52px;
  height: 52px;
}

.btn-bg-lr {
  margin: 0 10px;
}

.menu-text {
  font-size: 16px;
  color: #90d6dd;
}

.search-box {
  background: #fff;
  border-radius: 8px;
  width: 380px;
  height: 34px;
  position: relative;
  z-index: 1;
  padding: 0 10px;
  box-shadow: 0 4px 0 0 #dfe2e4, 0px 4px 8px rgb(90 0 0 / 20%);
  margin-left: 40px;
  box-sizing: border-box;
}

.search-img {
  width: 32px;
  height: 32px;
  margin: 0 2px 0 4px;

  display: flex;
  align-items: center;
}

.search-input {
  height: 100%;
  border: 0;
  outline: 0;
  font-size: 14px;
}

.search-input::placeholder {
  color: #c9e5e8;
}

.sign-box {
  margin-right: 87px;
}

.btn-bg {
  box-sizing: border-box;
  width: 104px;
  background: #fff;
  border-radius: 10px;
  height: 34px;
  padding: 0 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #90d6dd;
  /* box-shadow: 0 4px 0 0 #dfe2e4, 0px 4px 8px rgb(90 0 0 / 20%); */
  color: #90d6dd;
  cursor: pointer;
}

.login-in {
  width: 22px;
  height: 22px;
  /* margin-left: 15px; */
}

.list-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: unset;
  padding: 6px 7px;
  box-sizing: border-box;
}

.horizontal-line {
  width: 16px;
  border: 1px solid #90d6dd;
}

.btn-menu {
  /* margin-left: 10px; */
}

.main {
  /* padding-top: 120px; */
}

.input-box {
  width: 60%;
  margin: 0 auto;
}

.sign-in {
  flex: 1;
}

.sign-input-box {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 6px;
  height: 30px;
  box-shadow: 0 5px 0 #ddd;
  padding: 0 10px;
  margin-bottom: 20px;
}

.sign-input {
  outline: 0;
  height: 100%;
  border: 0;
  padding: 0 15px;
}

::v-deep().el-dialog {
  background: #f4f4f4;
  border-radius: 10px;
}

::v-deep().el-button {
  min-height: 30px;
  box-shadow: unset;
}

.btn-sign {
  background: #273441;
  border: 0;
  padding: 6px 30px;
  box-shadow: inset 0px -1px 4px 0px rgba(255, 255, 255, 0.47), 0 4px 0 #273441 !important;
  border-radius: 24px;
  color: #fff;
}

.btn-sign:hover {
  background: #273441;
  color: #fff;
  transform: scale(1.1);
}
</style>
