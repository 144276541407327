import {httpDelete, httpGet, httpPost, httpPut} from "@/https/index";
import {TypeData, visitorsLoginData} from "@/typing/indexPage";
import {Question, Type} from "@/typing";
import {RouteParamValue} from "vue-router";
// import {IHrSelectInterviewer} from "@/typing/type";

// 游客登录
export const banners = () => httpGet<any>("/banners");
// 游客登录
export const visitorsLogin = () => httpPost<visitorsLoginData>("/visitors/login");
// 获取一级标签
export const typesTags = () => httpGet<TypeData[]>("/types/tags");

// 标签点击
export const incrementTag = (id: number,data: any) => httpPost<any>("/types/"+id+"/increment-tag",data);

// 获取二级标签
export const childtypes = (id: string | RouteParamValue[]) => httpGet<TypeData[]>("/types/"+id+"/child");


// 获取二级标签下的题
export const childtypeQuestions = (id: string | RouteParamValue[]) => httpGet<Question[]>("/types/"+id+"/questions");

// 获取二级标签下的题
export const questionsAnswers = (data: any) => httpPost<any>("/questions/answers",data);


// 获取二级标签
export const clearAnswers = (id: any) => httpPost<any>("/types/"+id+"/clearAnswers");

// 用户登录
export const usersLogin = (data: any) => httpPost<any>("/users/login",data);

// 用户登录
export const usersRegister= (data: any) => httpPost<any>("/users/register",data);

// 用户登录
export const userTypesTags= () => httpGet<any>("/types/user_tags");
