import axios from "axios";
import {ElMessage} from 'element-plus'
import {User} from "@/typing";


// 创建http实例
const $http = axios.create({
    // baseURL: "http://127.0.0.1:8000/api/v1/",

    // baseURL: "http://192.168.3.73:8000/api/v1/",
    baseURL: "/api/v1/",

    timeout: 2000,
    headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-Requested-With": "XMLHttpRequest"
    }
})

export interface Names {
    code: number;
}

// 请求拦截
$http.interceptors.request.use(config => {
    config.headers = config.headers || {}
    const token: string = getToken();
    config.headers.Authorization = "Bearer " + token;
    return config
})
$http.interceptors.response.use(res => {
    const code: number = res.data.code
    if (code == 201) {
        ElMessage.success(res.data.message)
    }
    return res.data
}, error => {

    if (!error || !error.response) {
        ElMessage.error("网络错误")
    }
    const data = error?.response?.data
    const  status = error?.response?.status
    if (status=== 422) {
        data.code = 422
        return Promise.resolve(data)
    }

    if ([500, 404].indexOf(status)>=0) {
        ElMessage.error(data.message)
        return Promise.reject(data);
    }

    if (status === 400) {
        ElMessage.error(data.message)
        return Promise.resolve(data);
    }

    return Promise.reject(data)
})


export interface ResponseData<T=any> {
    status: string;
    code: number;
    message?: string
    data?: T;
    errors?: any;
}
export const httpGet = <T>(url: string, params?: any): Promise<ResponseData<T>> => {
    return $http.get(url, {params});
}

export const httpPost = <T>(url: string, params?: any): Promise<ResponseData<T>> => {
    return $http.post(url, params);
}
export const httpPut = <T>(url: string, params?: any): Promise<ResponseData<T>> => {
    return $http.put(url, params);
}

export const httpDelete = <T>(url: string, params?: any): Promise<ResponseData<T>> => {
    return $http.delete(url, params);
}


const tokenKey = 'token'
export const getToken = (): string | null => {
    return sessionStorage.getItem(tokenKey);
}
export const saveToken = (token: string) => {
  sessionStorage.setItem(tokenKey, token);
}
export const clearToken = () => {
  sessionStorage.removeItem(tokenKey);
}

const userKey = 'user'
export const getUser = (): User => {
    return JSON.parse(sessionStorage.getItem(userKey));
}

export const saveUser = (user: User) => {
  sessionStorage.setItem(userKey,  JSON.stringify(user));
}
export const clearUser = () => {
  sessionStorage.removeItem(userKey);
}




export default $http