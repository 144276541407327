import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import './assets/fonts/iconfont/iconfont.css';
import './assets/fonts/iconfont/iconfont.js';

import {noRepetition} from './share/directive-util'
// import 'element-plus/lib/theme-chalk/index.css';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import locale from 'element-plus/lib/locale/lang/zh-cn';

import {createPinia} from "pinia";

const pinia = createPinia();


const app = createApp(App)
    .use(ElementPlus, {locale})
    .use(store)
    .use(router)
    .use(pinia)
    .use(noRepetition);

router.isReady().then(() => {
    app.mount('#app');
});